
import { defineComponent, reactive, onMounted, ref } from "vue";
import Calendar from "@/components/Calendar.vue";
import { useRouter } from "vue-router";
import Header from "@/components/Header.vue";
import { RecordService } from "@/services/attendance/record/RecordService.ts";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";
import moment from "moment";

export default defineComponent({
  name: "MyAttendancePage",
  components: {
    Header,
    Calendar,
  },
  ionViewWillEnter() {
    if (this.year != -1) {
      this.resetPage();
    }
  },
  setup() {
    const dataList = reactive({ data: new Array() });
    const tjTypeList = reactive({ data: new Array() });
    const errorDate = reactive({ data: new Array() });
    const todayDate = ref(new Date());
    const year = ref(-1);
    const month = ref(-1);
    const selectDate = ref("");
    const todayDetail = ref({
      id: "",
      date: "",
      alldayStatus: -1,
      unApplyStatus: null,
    });
    const attendanceCity = ref({ id: "" });
    const isMore = ref(false);
    const isOpen = ref(true);
    const showExt = ref(false);
    const showErrBtn = ref(false);
    const router = useRouter();
    let pointDate: any = new Date();

    const recordService = new RecordService();
    const currentUser: any = SystemUtils.loginUser;
    let searchParams = reactive({
      userId: currentUser.id,
      month: -1,
      year: -1,
    });

    const initData = () => {
      // 所在的月的第1天，最后一天
      let thisDate = moment(pointDate).format("YYYY-MM-DD");
      year.value = parseInt(thisDate.substring(0, 4));
      month.value = parseInt(thisDate.substring(5, 7));
      selectDate.value = thisDate;
      resetPage();
      getAttendanceCity();
    };
    const resetPage = () => {
      getTjTypeCount();
      getListRequest((res: any) => {
        dataList.data = res;
        //alldayStatus 1:正常 0：未打卡 2：旷工    unApplyStatus null:未打卡异常申请 2：拒绝异常打卡申请  date:需小于今天
        let list = dataList.data.filter((v: any) => {
          let tTime = moment(new Date(v.date.replace(/\-/g, "/")))
            .endOf("day")
            .format("yyyy-MM-DD HH:mm:ss");
          return (
            v.alldayStatus == 2 &&
            (v.unApplyStatus == null || v.unApplyStatus == 2) &&
            new Date(tTime.replace(/\-/g, "/")) < new Date()
          );
        });
        errorDate.data = [];
        list.forEach((ele: any) => {
          errorDate.data.push(ele.date);
        });
        getDate();
      });
    };
    const doRefresh = (event: any) => {
      resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 600);
    };
    const getListRequest = (callback: any, errCalback?: any) => {
      searchParams.year = year.value;
      searchParams.month = month.value;
      recordService
        .getList(searchParams)
        .then((res: any) => {
          callback(res);
        })
        .catch(() => {
          if (errCalback) {
            errCalback();
          }
        });
    };
    const getAttendanceCity = () => {
      let params = { userId: currentUser.id };
      recordService
        .getAttenCity(params)
        .then((res: any) => {
          attendanceCity.value = res;
        })
        .catch(() => {});
    };
    const bindResultEmit = (r: any) => {
      if (r) {
        //选择时间结果
        if (r.type == 0 || r.type == 2) {
          const startTime = moment(new Date(r.pointDay)).format("YYYY-MM-DD");
          selectDate.value = startTime;
          getDate();

          //上一月/下一月
          if (r.type == 2) {
            year.value = new Date(r.firstDay).getFullYear();
            month.value = new Date(r.firstDay).getMonth() + 1;
            selectDate.value = r.pointDay;
            resetPage();
          }
        }
        //更多
        else if (r.type == 1) {
        }
      }
    };
    const getDate = () => {
      let listTemp = dataList.data.filter((ele: any) => {
        return ele.date == selectDate.value;
      });
      if (listTemp.length > 0) {
        todayDetail.value = listTemp[0];
        //alldayStatus 1:正常 0：未打卡 2：旷工    unApplyStatus null:未打卡异常申请 2：拒绝异常打卡申请  date:需小于今天
        let tTime =
          moment(todayDetail.value.date)
            .endOf("day")
            .format("yyyy-MM-DD HH:mm:ss") + "";
        if (
          todayDetail.value.alldayStatus == 2 &&
          (todayDetail.value.unApplyStatus == null ||
            todayDetail.value.unApplyStatus == 2) &&
          new Date(tTime.replace(/\-/g, "/")) < new Date()
        ) {
          showErrBtn.value = true;
        } else {
          showErrBtn.value = false;
        }
      } else {
        todayDetail.value = {
          id: "",
          date: "",
          alldayStatus: -1,
          unApplyStatus: null,
        };
        showErrBtn.value = false;
      }
    };
    //统计数据
    const getTjTypeCount = () => {
      /* service
        .getMyList(searchParams)
        .then((res: any) => {
            errorDate.data=res.data;
        })
        .catch(() => {
          if (errCalback) {
            errCalback();
          }
        });*/

      tjTypeList.data = [
        {
          text: "迟到",
          isMore: false,
          class: "count-color-late",
          count: 1,
        },
        {
          text: "早退",
          isMore: false,
          class: "count-color-leaveearly",
          count: 2,
        },
        {
          text: "缺卡",
          isMore: false,
          class: "count-color-unduty",
          count: 0,
        },
        {
          text: "外勤",
          isMore: false,
          class: "count-color-isout",
          count: 0,
        },
        {
          text: "更多",
          isMore: true,
          class: "count-color-more",
          count: 0,
        },
      ];
    };
    const applyHandler = () => {
      router.push({
        name: "AttendanceApply",
        params: { id: todayDetail.value.id },
      });
      // router.push({
      //   name: "AttendanceApply",
      //   params: { businessId: todayDetail.value.id,
      //   procId:1,taskId:1,procStatus:0 },
      // });
    };
    onMounted(() => {
      initData();
    });
    return {
      pointDate,
      todayDetail,
      isMore,
      isOpen,
      showExt,
      bindResultEmit,
      errorDate,
      tjTypeList,
      showErrBtn,
      applyHandler,
      attendanceCity,
      todayDate,
      doRefresh,
      resetPage,
      year,
    };
  },
});
